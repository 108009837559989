import axios from 'axios';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import VModal from 'vue-js-modal';
import NpButton from '@/components/NpButton.vue';
import ScreenLoader from '@/components/ScreenLoader.vue';
import { currency, dateFormat, phoneFormat } from '@/filters';
import App from './App.vue';
import './icons';
import router from './router';
import store from './store';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-Api-Token'] = TOKEN;
axios.defaults.headers.common['X-Sub-Location'] = TOKEN_DATA.sub_location;
axios.defaults.baseURL = '/api';

// Vue Filters
Vue.filter('currency', currency);
Vue.filter('date', dateFormat);
Vue.filter('phone', phoneFormat);

// Vue Components
Vue.use(VeeValidate, {
	classes: true,
	inject: false,
	events: 'change|changed|on-close',
});
Vue.use(VModal, { componentName: 'NpModal' });
Vue.component('NpButton', NpButton);
Vue.component('ScreenLoader', ScreenLoader);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
